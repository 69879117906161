import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import { Container } from '../../components/container';
import { Layout } from '../../components/layout';
import { SEO } from '../../components/seo';
import { TwoColumnText } from '../../components/two-column-text/TwoColumnText';
import { PageHeader, PageSubHeader } from '../../styles';
import { splitAndChunkText } from '../../utils/splitAndChunkText';

export const AboutPage: React.FC = () => {
  const { aboutPage } = useStaticQuery<GatsbyTypes.AboutPageQuery>(graphql`
    query AboutPage {
      aboutPage: contentfulPage(slug: { eq: "about" }) {
        headerText
        subHeaderText
        description {
          description
        }
        copy {
          copy
        }
        keywords
        images {
          fluid(quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `);

  const paragraphChunks = splitAndChunkText({ text: aboutPage?.copy?.copy });

  return (
    <Layout>
      <SEO
        title="About"
        description={aboutPage?.description?.description}
        keywords={aboutPage?.keywords?.length ? aboutPage?.keywords : undefined}
      />
      <div className="py-10 xl:py-20">
        <Container>
          <PageHeader>{aboutPage?.headerText}</PageHeader>
          <PageSubHeader>{aboutPage?.subHeaderText}</PageSubHeader>
          <div className="px-4 text-xl leading-relaxed xl:px-0 font-athiti">
            <TwoColumnText chunks={paragraphChunks} />
          </div>
          <div className="lg:flex lg:items-center lg:justify-between">
            {aboutPage?.images?.map(
              (image, idx) =>
                image?.fluid && (
                  <div
                    key={`image-${idx}`}
                    className="md:max-w-md md:mx-auto lg:mx-0 md:mb-4 lg:mb-0 lg:w-80 lg:h-80"
                  >
                    <Image className="w-full h-full" fluid={image?.fluid} />
                  </div>
                )
            )}
          </div>
        </Container>
      </div>
    </Layout>
  );
};
